<template>
  <v-data-table
    :headers="headers"
    :items="arInvoiceTypeList"
    :loading="loading"
    class="dt-listing-simple"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template #[`item.pivot.print`]="{ item }">
      <invoice-print-select
        :value="item.pivot.print"
        @change="updateInvoiceType($event, 'pivot.print', item)"
      />
    </template>

    <template #[`item.pivot.price_with_tax`]="{ item }">
      <v-switch
        :input-value="item.pivot.price_with_tax"
        @change="updateInvoiceType($event, 'pivot.price_with_tax', item)"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import { AppModule } from "@/store/app";
import { InvoiceTypeData } from "@planetadeleste/vue-mc-gw";
import InvoicePrintSelect from "@/components/form/fields/InvoicePrintSelect.vue";
import { assign, forEach, get, omit, set } from "lodash";

@Component({
  components: { InvoicePrintSelect },
})
export default class CompanyInvoiceTypeTable extends Mixins(DataTableMixin) {
  arInvoiceTypeList: InvoiceTypeData[] = [];
  loading = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
    { text: "invoice.print", value: "pivot.print", sortable: false },
    {
      text: "invoice.price.with.tax",
      value: "pivot.price_with_tax",
      sortable: false,
    },
  ];

  get company() {
    return AppModule.company;
  }

  async updateInvoiceType(
    sValue: any,
    sPath: string,
    obData: Partial<InvoiceTypeData>
  ) {
    set(obData, sPath, sValue);
    const obDataPivot = omit(get(obData, "pivot", {}), [
      "company_id",
      "invoice_type_id",
    ]);

    const arInvoiceTypeIdList: Record<number, any> = {};
    forEach(this.arInvoiceTypeList, (obItem: InvoiceTypeData) => {
      const obPivot: Record<string, any> = omit(get(obItem, "pivot", {}), [
        "company_id",
        "invoice_type_id",
      ]);

      if (obData.id === obItem.id) {
        assign(obPivot, obDataPivot);
      }

      set(arInvoiceTypeIdList, obItem.id, obPivot);
    });

    this.loading = true;
    await this.company.addUpdateInvoiceTypes(arInvoiceTypeIdList as number[]);
    this.loading = false;
  }

  async loadCompanyInvoiceTypes() {
    if (!this.company || !this.company.id) {
      return;
    }

    this.loading = true;
    const obResponse = await this.company.getInvoiceTypes();
    this.arInvoiceTypeList = obResponse.getData().data;
    this.loading = false;
  }

  async mounted() {
    this.mapDTHeaders();
    await this.loadCompanyInvoiceTypes();
  }
}
</script>
